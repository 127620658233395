import React, { ReactNode } from 'react'

import Box from '@mui/material/Box'

export default function ExternalPageWrapper({ children }: { children: ReactNode }) {
  return (
    <Box component="article" px={4.5} py={3}>
      {children}
    </Box>
  )
}
