import React, { ReactNode } from 'react'

import Divider from '@mui/material/Divider'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'

import { Footer } from 'components/templates/ExternalPage/ExternalPageFooter.styles'
import SupportEmailLink from 'components/templates/ExternalPage/SupportEmailLink'

export default function ExternalPageFooter({
  children,
  disableDivider = false,
  ...props
}: {
  children?: ReactNode
  disableDivider?: boolean
}) {
  return (
    <>
      {!disableDivider && <Divider sx={{ mx: -4.5, my: 4 }} />}
      <Footer {...props}>
        {children || (
          <Typography variant="body2">
            For further support, visit our{' '}
            <Link href="https://help.createiq.tech" target="_blank" rel="noreferrer">
              Help Centre
            </Link>{' '}
            for resources, or get in touch with us at <SupportEmailLink />.
          </Typography>
        )}
      </Footer>
    </>
  )
}
