'use client'

import React, { ReactNode } from 'react'

import Button, { ButtonProps } from '@mui/material/Button'

import useSupportLinkProps from 'components/templates/ExternalPage/useSupportLinkProps'

export default function SupportEmailButton({ children, ...props }: { children?: ReactNode } & ButtonProps) {
  const supportLinkProps = useSupportLinkProps()

  return (
    <Button component="a" {...supportLinkProps} {...props}>
      {children || 'Contact Support'}
    </Button>
  )
}
