import React, { ReactNode } from 'react'

import HelpOutlineIcon from '@mui/icons-material/NotListedLocationOutlined'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import dynamic from 'next/dynamic'

import LinkedInIcon from 'components/icons/LinkedIn'
import AutoLogout from 'layout/ExternalLayout/components/AutoLogout'
import ExternalLayoutFooterLogo from 'layout/ExternalLayout/components/ExternalLayoutFooterLogo'
import ExternalLayoutLogo from 'layout/ExternalLayout/components/ExternalLayoutLogo'
import {
  BackgroundContainer,
  Content,
  ContentBoxGlow,
  Footer,
  FooterAside,
  FooterIconLink,
  FooterLinks,
  FooterRow,
  HeaderLink,
  HeaderToolbar,
  LeftBackgroundContainer,
  Main,
  RightBackgroundContainer,
  TopBar,
} from 'layout/ExternalLayout/ExternalLayout.styles'

const UserSwitcher = dynamic(() => import('components/organisms/UserSwitcher/UserSwitcher'))

export default function ExternalLayout({
  children,
  disableAutoLogout,
}: {
  children: ReactNode
  disableAutoLogout?: boolean
}) {
  return (
    <Stack minHeight="100vh" direction="column">
      {!disableAutoLogout && <AutoLogout />}
      <BackgroundContainer>
        <LeftBackgroundContainer />
        <RightBackgroundContainer />
      </BackgroundContainer>

      <TopBar position="static" elevation={0}>
        <Stack
          sx={{ padding: '0.5rem 1rem' }}
          direction="row"
          alignItems="stretch"
          justifyContent="space-between"
          gap={2}
        >
          <Box px={2.5} py={1.625}>
            <ExternalLayoutLogo />
          </Box>
          <Box marginRight="auto" flexGrow={1} display={{ xs: 'none', sm: 'flex' }}>
            <HeaderToolbar>
              <HeaderLink href="/login">Log in</HeaderLink>
              <HeaderLink
                component="a"
                title="About CreateiQ"
                href="https://www.linklaters.com/en/about-us/createiq"
                target="_blank"
                rel="noreferrer"
              >
                About us
              </HeaderLink>
              <HeaderLink href="/lookup">Look-up</HeaderLink>
            </HeaderToolbar>
          </Box>
          <Box sx={{ display: { xs: 'none', md: 'block' } }}>
            <IconButton
              sx={{ m: 0.375 }}
              color="primary"
              href="https://help.createiq.tech"
              target="_blank"
              aria-label="Help Centre"
            >
              <HelpOutlineIcon />
            </IconButton>
            {process.env.NEXT_PUBLIC_USER_SWITCHER === 'true' && <UserSwitcher />}
          </Box>
        </Stack>
      </TopBar>

      <Main as="main">
        <Content>
          <ContentBoxGlow anchor="top" />
          <ContentBoxGlow anchor="bottom" />
          {children}
        </Content>
      </Main>
      <Footer as="footer" height="fit-content">
        <FooterRow>
          <FooterAside>
            <ExternalLayoutFooterLogo />
            <Stack direction="row" spacing={2} useFlexGap>
              <FooterIconLink
                color="primary"
                component={Link}
                href="https://www.linkedin.com/company/createiq"
                target="_blank"
                rel="noreferrer"
              >
                <LinkedInIcon sx={{ fontSize: 40 }} />
              </FooterIconLink>
            </Stack>
          </FooterAside>
          <FooterAside>
            <Typography>&copy; Copyright {new Date().getFullYear()} Linklaters LLP. All rights reserved.</Typography>
          </FooterAside>
          <FooterLinks>
            {/* External link so use component="a" to bypass the router */}
            <Link component="a" href="/privacy" target="_blank" underline="none" color="primary">
              Privacy
            </Link>
            <Link href="/terms-and-conditions" underline="none" color="primary">
              Terms of Use
            </Link>
            {/* External link so use component="a" to bypass the router */}
            <Link component="a" href="/cookie" target="_blank" underline="none" color="primary">
              Cookies
            </Link>
            <Link href="/technical-requirements" underline="none" color="primary">
              Technical Requirements
            </Link>
          </FooterLinks>
        </FooterRow>
      </Footer>
    </Stack>
  )
}
